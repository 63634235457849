// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --square-size: 20px;
  --triangle-long: 15px;
  --triangle-short: 10px;
}

.maze-cell {
  position: relative;
  width: var(--square-size);
  height: var(--square-size);
}

.maze-cell-north {
  border-top: 3px solid black;
}

.maze-cell-east {
  border-right: 3px solid black;
}

.maze-cell-south {
  border-bottom: 3px solid black;
}

.maze-cell-west {
  border-left: 3px solid black;
}

.start {
  background-color: green;
}

.goal {
  background-color: red;
}

.robot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--square-size);
  height: var(--square-size);
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
}

.robot-triangle {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0;
  border-bottom-width: var(--triangle-long);
  border-left-width: var(--triangle-short);
  border-right-width: var(--triangle-short);
  border-color: transparent transparent darkred transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/Maze.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,yBAAyB;EACzB,0BAA0B;EAC1B,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,QAAQ;EACR,SAAS;EACT,mBAAmB;EACnB,eAAe;EACf,yCAAyC;EACzC,wCAAwC;EACxC,yCAAyC;EACzC,yDAAyD;AAC3D","sourcesContent":[":root {\n  --square-size: 20px;\n  --triangle-long: 15px;\n  --triangle-short: 10px;\n}\n\n.maze-cell {\n  position: relative;\n  width: var(--square-size);\n  height: var(--square-size);\n}\n\n.maze-cell-north {\n  border-top: 3px solid black;\n}\n\n.maze-cell-east {\n  border-right: 3px solid black;\n}\n\n.maze-cell-south {\n  border-bottom: 3px solid black;\n}\n\n.maze-cell-west {\n  border-left: 3px solid black;\n}\n\n.start {\n  background-color: green;\n}\n\n.goal {\n  background-color: red;\n}\n\n.robot {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: var(--square-size);\n  height: var(--square-size);\n  background-color: orange;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.robot-triangle {\n  content: \"\";\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 0;\n  border-bottom-width: var(--triangle-long);\n  border-left-width: var(--triangle-short);\n  border-right-width: var(--triangle-short);\n  border-color: transparent transparent darkred transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
